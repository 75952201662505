.App-header {
  opacity: 1;
  transition: opacity 1.5s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.App-header.hidden {
  opacity: 0;
  transition: opacity .4s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.App-logo {
  width: 85vw;
}

.App-iconItem {
  width: 40px;
}

.App-icon *:nth-child(n + 2) {
  margin-left: 18px;
}

.App-icon {
  margin-top: 3vh;
}

.App-icon a {
  opacity: 1;
  transition: opacity 0.1s linear;
}

.App-icon a:hover {
  opacity: 0.6;
  transition: opacity 0s linear;
}

.App-header {
  display: flex;
  width: 100%;
  height: 100vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.App-bodyWrapper {
  width: 100%;
}

.App-biography {
  width: 100%;
  padding: 2rem;
  margin: auto;
  color: #444444;
  line-height: 1.8;
  font-size: 1rem;
}

.App-biography.hidden {
  display: none;
}

.App-biographyHead {
  display: flex;
  align-items: center;
}

.App-biographyPhoto {
  width: 64px;
  height: 64px;
  border-radius: 50%;
  border: 1.02px solid #4b3139;
}

.App-biographyHeadData {
  margin-left: 0.5rem;
  color: #4b3139;
}

.App-biographyName {
  margin: 0;
  font-size: 1.8rem;
  font-weight: bold;
  line-height: 1em;
}

.App-biographyText p {
  margin-bottom: 1.5rem;
}

.App-biographyNameEn {
  margin: 0;
}

.App-biographyLink {
  position: absolute;
  bottom: 0.5rem;
  width: 100%;
  color: #4b3139;
  font-size: 0.8rem;
  user-select: none;
}

.App-biographyLinkText {
  text-align: center;
  opacity: 1;
  transition: opacity 0.4s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.App-biographyLinkText.hidden {
  opacity: 0;
}

.App-footer {
  margin: 100px 0;
  text-align: center;
  font-size: 0.8rem;
}

.App-footerToggleLang {
  cursor: pointer;
  text-decoration: underline;
}

@media screen and (min-width: 600px) and (max-width: 959px) {
  .App-logo {
    width: 70vw;
  }

  .App-icon {
    margin-top: 5vh;
  }

  .App-biography {
    width: 540px;
    padding: 0;
  }

  .App-iconItem {
    width: 4.2vw;
  }

  .App-icon *:nth-child(n + 2) {
    margin-left: 1.4vw;
  }
}

@media screen and (min-width: 960px) {
  .App-logo {
    width: 50vw;
  }

  .App-icon {
    margin-top: 5vh;
  }

  .App-biography {
    width: 540px;
    padding: 0;
  }

  .App-iconItem {
    width: 3vw;
  }

  .App-icon *:nth-child(n + 2) {
    margin-left: 1vw;
  }
}
